import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap'; // Import Spinner
import { login } from './apiOperations'; // Import the login function
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await login(email, password); // Call the login function
      navigate('/dashboard');
    } catch (error) {
      setError(error.message); // Handle error from the apiOperations.js
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <Container className="login-container" style={{ maxWidth: '400px', marginTop: '100px' }}>
      <h2 className="text-center mb-4">Login</h2>
      <Form onSubmit={handleLogin}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password:</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        {error && <Alert variant="danger">{error}</Alert>}

        <Button variant="primary" type="submit" className="w-100" disabled={loading}>
          {loading ? <><Spinner animation="border" size="sm" /> Loading...</> : 'Login'}
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
