import React, { useState } from 'react';
import { Navbar, Container, Row, Col, Card, Nav } from 'react-bootstrap';
import User from './user/User';
import Product from './product/Product';
import Category from './category/Category';
import Subcategory from './subcategory/Subcategory';

const sampleCounts = {
  users: 100,
  products: 150,
  categories: 10,
  subCategories: 20,
  cart: 5,
  orders: 50,
};

const Sidebar = ({ onSelect, activeContent }) => {
  return (
    <div className="bg-white text-white fixed-top d-flex flex-column align-items-start p-3" style={{ width: '250px', height: '100vh', position: 'fixed', top: '0', left: '0', background: 'linear-gradient(135deg, #1E3A8A, #3B82F6)', color: 'white', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      <h5 className="mb-4 w-100 p-2" style={{ background: 'linear-gradient(135deg, #1E3A8A, #3B82F6)', color: 'white', borderRadius: '0.25rem' }}>KKR</h5>
      <Nav className="flex-column w-100">
        {['Users', 'Categories', 'SubCategories', 'Products', 'Carts', 'Orders', 'Reviews', 'Addresses'].map(item => (
          <Nav.Link 
            key={item} 
            className={`text-white py-2 ${activeContent === item ? 'font-weight-bold' : ''}`} 
            onClick={() => onSelect(item)}
            style={activeContent === item ? { boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' } : {}}
          >
            {item}
          </Nav.Link>
        ))}
      </Nav>
    </div>
  );
};

const Ecommerce = () => {
  const [activeContent, setActiveContent] = useState('Users');

  const handleButtonClick = (content) => {
    setActiveContent(content);
  };

  return (
    <div className="d-flex" style={{ minHeight: '100vh' }}>
      <Sidebar onSelect={handleButtonClick} activeContent={activeContent} />

      <div className="flex-grow-1" style={{ marginLeft: '250px', backgroundColor: '#f8f9fa', minHeight: '100vh', padding: '20px' }}>
        <Navbar className="mb-4" style={{ background: 'linear-gradient(135deg, #1E3A8A, #3B82F6)', color: 'white', backdropFilter: 'blur(8px)', borderRadius: '10px' }}>
          <Container>
            <Navbar.Brand className="font-weight-bold">
              KKR Ecommerce Admin Dashboard
            </Navbar.Brand>
          </Container>
        </Navbar>

        <Container>
          <Row>
            <Col md={3}>
              <Card className="mb-4 bg-danger text-light" style={{ border: 'none', borderRadius: '10px' }}>
                <Card.Body>
                  <Card.Title>Users</Card.Title>
                  <Card.Text>{sampleCounts.users}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4 bg-info text-light" style={{ border: 'none', borderRadius: '10px' }}>
                <Card.Body>
                  <Card.Title>Products</Card.Title>
                  <Card.Text>{sampleCounts.products}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4 bg-success text-light" style={{ border: 'none', borderRadius: '10px' }}>
                <Card.Body>
                  <Card.Title>Categories</Card.Title>
                  <Card.Text>{sampleCounts.categories}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4 bg-warning text-light" style={{ border: 'none', borderRadius: '10px' }}>
                <Card.Body>
                  <Card.Title>SubCategories</Card.Title>
                  <Card.Text>{sampleCounts.subCategories}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div>
            {activeContent === 'Users' && <User />}
            {activeContent === 'Categories' && <Category />}
            {activeContent === 'SubCategories' && <Subcategory />}
            {activeContent === 'Products' && <Product />}
            {activeContent === 'Carts' && <h3 className="text-dark">Displaying Cart Content</h3>}
            {activeContent === 'Orders' && <h3 className="text-dark">Displaying Orders Content</h3>}
            {activeContent === 'Reviews' && <h3 className="text-dark">Add a Review</h3>}
            {activeContent === 'Addresses' && <h3 className="text-dark">Address Details</h3>}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Ecommerce;
