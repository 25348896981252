import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Image, Spinner, Alert } from 'react-bootstrap';
import CreateCategoryForm from './CreateCategoryForm';
import UpdateCategoryForm from './UpdateCategoryForm';
import { deleteCategory, getCategory } from '../apiOperations';

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [showCreateCategoryForm, setShowCreateCategoryForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadData = async () => {
    setLoading(true);
    setError(null);
    try {
      const categoriesData = await getCategory();
      setCategories(categoriesData);
    } catch (error) {
      setError('Failed to load data');
      console.error('Failed to load data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleUpdateComplete = () => {
    loadData(); // Refresh data after update
  };

  const handleCreateCategoryFormOpen = () => {
    setShowCreateCategoryForm(true);
  };

  const handleFormClose = () => {
    setShowCreateCategoryForm(false);
    setShowUpdateForm(false);
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this category?');
    if (!confirmed) {
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await deleteCategory(id);
      loadData(); // Refresh data after delete
    } catch (error) {
      setError(`Failed to delete category with ID ${id}`);
      console.error(`Failed to delete category with ID ${id}`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (category) => {
    setSelectedCategory(category);
    setShowUpdateForm(true);
  };

  return (
    <Container className="mt-4">
      <h3>Categories</h3>
      <Button variant="primary" onClick={handleCreateCategoryFormOpen}>
        Add Category
      </Button>

      {loading && <Spinner animation="border" role="status" className="mt-3">
        <span className="visually-hidden">Loading...</span>
      </Spinner>}

      {error && <Alert variant="danger" className="mt-3">
        {error}
      </Alert>}

      <Table striped bordered hover responsive className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <tr key={category.categoryId}>
              <td>{category.categoryId}</td>
              <td>{category.categoryName}</td>
              <td>
                {category.categoryImage ? (
                  <Image crossOrigin='anonymous'
                    src={`https://www.api.karthikeshrobotics.in/uploads/category/${category.categoryImage}`}
                    alt={`${category.categoryImage}`}
                    rounded
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                  />
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                <Button variant="warning" onClick={() => handleUpdate(category)} className="me-2">
                  Update
                </Button>
                <Button variant="danger" onClick={() => handleDelete(category.categoryId)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for CreateCategoryForm */}
      <CreateCategoryForm
        show={showCreateCategoryForm}
        onClose={handleFormClose}
        onCreate={loadData}
      />

      <UpdateCategoryForm
        show={showUpdateForm}
        onClose={handleFormClose}
        onUpdate={handleUpdateComplete}
        category={selectedCategory}
      />
    </Container>
  );
};

export default Category;
