import React, { useState } from 'react';
import { Button, Form, Modal, Image } from 'react-bootstrap';
import { createProduct } from '../apiOperations';

const CreateProductForm = ({ show, onClose, onCreate, subcategories }) => {
  const [productName, setProductName] = useState('');
  const [productBrand, setProductBrand] = useState('');
  const [originalPrice, setOriginalPrice] = useState('');
  const [discountPrice, setDiscountPrice] = useState('');
  const [description, setDescription] = useState('');
  const [stock, setStock] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [error, setError] = useState('');

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setProductImages(files);
    setImagePreviews(files.map(file => URL.createObjectURL(file))); // Preview selected images
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('productBrand', productBrand);
    formData.append('originalPrice', originalPrice);
    formData.append('discountPrice', discountPrice);
    formData.append('description', description);
    formData.append('stock', stock);
    formData.append('subCategoryId', selectedSubcategory);
    productImages.forEach(file => formData.append('productImages', file));

    try {
      await createProduct(formData);
      onCreate(); // Refresh products after creation
      onClose(); // Close the form
    } catch (error) {
      setError('Failed to create product. Please try again.');
      console.error('Failed to create product', error);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formProductName">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formProductBrand">
            <Form.Label>Product Brand</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product brand"
              value={productBrand}
              onChange={(e) => setProductBrand(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formOriginalPrice">
            <Form.Label>Original Price</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              placeholder="Enter original price"
              value={originalPrice}
              onChange={(e) => setOriginalPrice(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formDiscountPrice">
            <Form.Label>Discount Price</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              placeholder="Enter discount price (optional)"
              value={discountPrice}
              onChange={(e) => setDiscountPrice(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter product description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formStock">
            <Form.Label>Stock Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter stock quantity"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formSubcategory">
            <Form.Label>Subcategory</Form.Label>
            <Form.Control
              as="select"
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
              required
            >
              <option value="">Select a subcategory</option>
              {subcategories.map(subcategory => (
                <option key={subcategory.subCategoryId} value={subcategory.subCategoryId}>
                  {subcategory.subCategoryName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formProductImages">
            <Form.Label>Product Images</Form.Label>
            <Form.Control
              type="file"
              multiple
              onChange={handleImageChange}
            />
            <div className="mt-3">
              {imagePreviews.map((preview, index) => (
                <Image
                  key={index}
                  src={preview}
                  alt={`Preview ${index}`}
                  style={{ width: '100px', height: 'auto', margin: '5px' }}
                />
              ))}
            </div>
          </Form.Group>

          {error && <p className="text-danger">{error}</p>}

          <Button variant="primary" type="submit">
            Create Product
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProductForm;
