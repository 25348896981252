import React, { useEffect, useState } from 'react';
import { Table, Container, Image, Card } from 'react-bootstrap';
import { getUsers } from '../apiOperations';

const User = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  const loadData = async () => {
    try {
      const usersData = await getUsers();
      setUsers(usersData);
    } catch (error) {
      setError('Failed to load user data');
      console.error('Failed to load data', error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header as="h3">Users</Card.Header>
        <Card.Body>
          {error && <p className="text-danger">{error}</p>}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Bio</th>
                <th>Location</th>
                <th>Profession</th>
                <th>Role</th>
                <th>Profile Pic</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.userId}>
                  <td>{user.userId}</td>
                  <td>{user.fullname}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.bio || 'N/A'}</td>
                  <td>{user.location || 'N/A'}</td>
                  <td>{user.profession || 'N/A'}</td>
                  <td>{user.role}</td>
                  <td>
                    {user.profilepic ? (
                     <Image crossOrigin='anonymous'
                     src={`https://www.api.karthikeshrobotics.in/uploads/dp/${user.profilepic}`}
                     alt={user.fullname}
                     rounded
                     style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                   />
                   
                    ) : (
                      'No Image'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default User;
