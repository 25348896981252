import axios from 'axios';

const API_BASE_URL = 'https://www.api.karthikeshrobotics.in';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor to add token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Login API call without token
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });

    if (response.data && response.data.token) {
      localStorage.setItem('token', response.data.token);
    }

    return response.data;
  } catch (error) {
    throw new Error('Invalid credentials. Please try again.');
  }
};

// Fetch users with token included automatically
export const getUsers = async () => {
  try {
    const response = await axiosInstance.get('/user/users');
    return response.data.users;
  } catch (error) {
    console.error('Error in getUsers:', error);
    throw error;
  }
};

// Fetch categories with token included automatically
export const getCategory = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/category/`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch categories');
  }
};

// Create a new category with token included automatically
export const createCategory = async (formData) => {
  try {
    await axiosInstance.post('/category', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw new Error('Failed to create category');
  }
};
// Update a category with token included automatically
export const updateCategory = async (id, formData) => {
  try {
    await axiosInstance.put(`/category/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw new Error('Failed to update category');
  }
};

// Delete category with token included automatically
export const deleteCategory = async (id) => {
  try {
    await axiosInstance.delete(`/category/${id}`);
  } catch (error) {
    throw new Error('Failed to delete category');
  }
};
export const getSubCategory = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/subcategory`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch subcategories');
  }
};

export const deleteSubCategory = async (id) => {
  try {
    await axiosInstance.delete(`/subcategory/${id}`);
  } catch (error) {
    throw new Error('Failed to delete subcategory');
  }
};

// Create a new category with token included automatically
export const createSubCategory = async (formData) => {
  try {
    await axiosInstance.post('/subcategory', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw new Error('Failed to create subcategory');
  }
};
// Update a category with token included automatically
export const updateSubCategory = async (id, formData) => {
  try {
    await axiosInstance.put(`/subcategory/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw new Error('Failed to update sub category');
  }
};
// Fetch products
export const getProduct = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/product`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch products', error);
    throw error;
  }
};
// Create a new product
export const createProduct = async (formData) => {
  try {
    await axiosInstance.post('/product', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Necessary for file uploads
      },
    });
  } catch (error) {
    console.error('Failed to create product', error);
    throw error;
  }
};

// Update an existing product
export const updateProduct = async (id,formData) => {
  try {
    await axiosInstance.put(`/product/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Necessary for file uploads
      },
    });
  } catch (error) {
    console.error('Failed to update product', error);
    throw error;
  }
};

// Delete a product
export const deleteProduct = async (id) => {
  try {
    await axiosInstance.delete(`/product/${id}`);
  } catch (error) {
    console.error(`Failed to delete product with ID ${id}`, error);
    throw error;
  }
};